define('menchies-dashboard/controllers/profile', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		session: _ember['default'].inject.service('session'),

		email: null,
		currentPassword: null,
		newPassword: null,

		errorMessage: null,
		successMessage: null,
		isLoading: false,

		actions: {
			save: function save() {
				var _this = this;

				var updatedEmail = true;
				var updatedPassword = true;

				// First, check email. If we see no changes then just ignore it
				if (this.get('email') === '' || this.get('email') === this.get('session.data.authenticated.email')) {
					updatedEmail = false;
				}

				// Also check the password
				if (this.get('newPassword') === '' || this.get('newPassword') === null) {
					updatedPassword = false;
				}

				var payload = {};
				if (updatedEmail) {
					payload.email = this.get('email');
				}
				if (updatedPassword) {
					if (!this.get('currentPassword')) {
						this.set('errorMessage', 'Current password must be provided if you are changing it');
						return;
					} else {
						payload.newPassword = this.get('newPassword').trim();
						payload.currentPassword = this.get('currentPassword').trim();
					}
				}

				this.set('isLoading', true);

				_ember['default'].$.ajax({
					type: 'POST',
					url: 'https://echo.harbortouch.com/api/v1/ext/menchies/profile',
					headers: {
						'X-Access-Token': this.get('session.data.authenticated.token')
					},
					contentType: 'application/json',
					data: JSON.stringify(payload)
				}).always(function (xhr, status) {
					_this.set('isLoading', false);
					console.log(xhr);
					if (status === 'success') {
						_this.set('successMessage', 'Changes saved successfully');
						setTimeout(function () {
							_this.set('successMessage', null);
						}, 5000);
						if (updatedEmail) {
							_this.set('session.data.authenticated.email', _this.get('email'));
						}
					} else {
						_this.setProperties({
							'errorMessage': 'Your changes were not saved',
							'errorMessageDetails': 'Error code: ' + xhr.responseJSON.error.message
						});
						setTimeout(function () {
							_this.setProperties({
								errorMessage: null,
								errorMessageDetails: null
							});
						}, 5000);
					}
				});
			}
		}
	});
});