define("menchies-dashboard/initializers/accounting", ["exports", "accounting/settings"], function (exports, _accountingSettings) {
  exports["default"] = {
    name: 'accounting.js',
    initialize: function initialize() {
      _accountingSettings.currency.symbol = "$";
      _accountingSettings.number.decimal = ".";
      _accountingSettings.number.thousand = ",";
    }
  };
});
// app/initializers/accounting.js