define('menchies-dashboard/controllers/reports/fundraiser-sales', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		queryParams: ['location', 'startsAt', 'endsAt'],
		location: null,
		startsAt: null,
		endsAt: null,
		actions: {
			submitReport: function submitReport() {
				this.get('controllers.reports').send('updateReportType');
				this.get('controllers.reports').send('submitReport');
			}
		}
	});
});