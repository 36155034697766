define('menchies-dashboard/routes/reports/labor-by-employee', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
	exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
		model: function model(params) {
			var _this = this;

			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				_ember['default'].$.ajax({
					url: 'https://echo.harbortouch.com/api/v1/ext/menchies/reports/labor-by-employee?location=' + params.location + '&start=' + params.startsAt + '&end=' + params.endsAt,
					headers: {
						'X-Access-Token': _this.get('session.data.authenticated.token')
					}
				}).always(function (xhr) {
					if (xhr && xhr.report) {
						return resolve(xhr);
					} else {
						return reject(new Error('report_failed'));
					}
				});
			});
		},
		actions: {
			error: function error(e) {
				console.log('Error thrown in the route: ' + e);
			}
		}
	});
});