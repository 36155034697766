define('menchies-dashboard/router', ['exports', 'ember', 'menchies-dashboard/config/environment'], function (exports, _ember, _menchiesDashboardConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _menchiesDashboardConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('dashboard');
    this.route('reports', function () {
      this.route('cup-count');
      this.route('fundraiser-sales');
      this.route('weekly-net-sales');
      this.route('employee-directory');
      this.route('discount-summary');
      this.route('labor-by-employee');
      this.route('labor-by-employee-by-job');
      this.route('quantity-on-hand');
      this.route('tender-summary');
      this.route('item-sales-summary');
    });
    this.route('profile', { path: '/profile' });
    this.route('sign-in', { path: '/sign-in' });
  });

  exports['default'] = Router;
});