define('menchies-dashboard/controllers/reports', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {

	var singleLocationReports = ['employee-directory', 'discount-summary', 'item-sales-summary', 'labor-by-employee', 'labor-by-employee-by-job', 'quantity-on-hand', 'tender-summary'];

	exports['default'] = _ember['default'].Controller.extend({
		session: _ember['default'].inject.service('session'),

		reportType: [{ name: 'cup-count', friendlyName: 'Menchie\'s Detail Summary' }, { name: 'fundraiser-sales', friendlyName: 'Fundraiser Sales' }, { name: 'weekly-net-sales', friendlyName: 'Weekly Net Sales' }, { name: 'employee-directory', friendlyName: 'Employee Directory' }, { name: 'discount-summary', friendlyName: 'Discount Summary' }, { name: 'item-sales-summary', friendlyName: 'Item Sales Summary' }, { name: 'labor-by-employee', friendlyName: 'Labor by Employee' }, { name: 'labor-by-employee-by-job', friendlyName: 'Labor by Employee by Job' }, { name: 'quantity-on-hand', friendlyName: 'Quantity on Hand' }, { name: 'tender-summary', friendlyName: 'Tender Summary' }],

		//General Report Flags
		singleLocationReportSelected: false,
		selectedLocation: null,
		selectedReport: null,

		//Date Flags
		selectedStartTime: _ember['default'].computed(function () {
			var time = (0, _moment['default'])().startOf('day').format('h:mm a');
			return time;
		}),
		selectedEndTime: _ember['default'].computed(function () {
			var time = (0, _moment['default'])().endOf('day').format('h:mm a');
			return time;
		}),
		selectedStartDate: null,
		selectedEndDate: null,

		//Error Flags
		selectedLocationError: null,
		selectedReportError: null,
		selectedStartDateError: null,
		selectedEndDateError: null,
		invalidReportGenerated: null,

		//Disable UI Flags
		startDateDisabled: false,
		startTimeDisabled: false,
		endDateDisabled: false,
		endTimeDisabled: false,

		actions: {
			selectAll: function selectAll() {
				_ember['default'].$('input[data-checkbox-type=location]').prop('checked', true);
			},
			selectNone: function selectNone() {
				_ember['default'].$('input[data-checkbox-type=location]').prop('checked', false);
			},
			updateReportType: function updateReportType() {
				if (singleLocationReports.indexOf(this.get('selectedReport')) !== -1) {
					this.set('singleLocationReportSelected', true);
				} else {
					this.set('singleLocationReportSelected', false);
				}

				this.set('selectedStartDate', null);
				this.set('selectedEndDate', null);

				this.set('startDateDisabled', false);
				this.set('startTimeDisabled', false);
				this.set('endDateDisabled', false);
				this.set('endTimeDisabled', false);

				if (this.get('selectedReport') === 'weekly-net-sales') {
					this.set('endDateDisabled', true);
					this.set('endTimeDisabled', true);
				}

				if (this.get('selectedReport') === 'employee-directory') {
					this.set('startDateDisabled', true);
					this.set('endDateDisabled', true);
					this.set('startTimeDisabled', true);
					this.set('endTimeDisabled', true);
				}
			},
			submitReport: function submitReport() {
				var selectedLocations;

				console.log('Selected Start Time: ' + this.get('selectedStartTime'));
				console.log('Selected End Time: ' + this.get('selectedEndTime'));

				var startTime = this.get('selectedStartTime');
				var endTime = this.get('selectedEndTime');
				var startDate = (0, _moment['default'])(this.get('selectedStartDate')).format("YYYY-MM-DD");
				var endDate = (0, _moment['default'])(this.get('selectedEndDate')).format("YYYY-MM-DD");

				var startDateAndTime = (0, _moment['default'])(startDate + " " + startTime, "YYYY-MM-DD h:mm A").toISOString();
				var endDateAndTime = (0, _moment['default'])(endDate + " " + endTime, "YYYY-MM-DD h:mm A").toISOString();

				if (!this.get('singleLocationReportSelected')) {
					selectedLocations = [];
					var selections = _ember['default'].$('input[data-checkbox-type=location]');
					for (var i = 0; i < selections.length; i++) {
						var current = $(selections[i]);
						if (current.is(':checked')) {
							selectedLocations.push(current.attr('data-location-id'));
						}
					}
				} else {
					selectedLocations = this.get('selectedLocation');
				}
				// Form Validation
				// Reset error message values before checking field values
				this.set('selectedLocationError', null);

				this.set('selectedLocationErrorMsg', null);
				this.set('selectedReportErrorMsg', null);
				this.set('selectedStartDateErrorMsg', null);
				this.set('selectedEndDateErrorMsg', null);

				// I still needed to use jQuery to add classes because the dropdowns are components and I can not
				// dynamically add a class with handlebars
				_ember['default'].$('.report-selection').removeClass('error');
				_ember['default'].$('.select-start-date').removeClass('error');
				_ember['default'].$('.select-end-date').removeClass('error');

				if (selectedLocations.length === 0) {
					this.set('selectedLocationError', 'location-error');
					this.set('selectedLocationErrorMsg', true);
					return;
				} else if (this.get('selectedReport') === null) {
					_ember['default'].$('.report-selection').addClass('error');
					this.set('selectedReportErrorMsg', true);
					return;
				} else if (this.get('selectedStartDate') === null && this.get('selectedReport') !== 'employee-directory') {
					_ember['default'].$('.select-start-date').addClass('error');
					this.set('selectedStartDateErrorMsg', true);
					return;
				} else if (this.get('selectedEndDate') === null && this.get('selectedReport') !== 'weekly-net-sales' && this.get('selectedReport') !== 'employee-directory') {
					_ember['default'].$('.select-end-date').addClass('error');
					this.set('selectedEndDateErrorMsg', true);
					return;
				}
				_ember['default'].$('.select-end-date').removeClass('error');

				// Little hack here to make sure the current route is unloaded
				// before the actual transition to a report page
				this.transitionToRoute('reports');

				switch (this.get('selectedReport')) {
					case 'weekly-net-sales':
					case 'cup-count':
					case 'fundraiser-sales':
					case 'employee-directory':
					case 'discount-summary':
					case 'item-sales-summary':
					case 'labor-by-employee':
					case 'labor-by-employee-by-job':
					case 'quantity-on-hand':
					case 'tender-summary':
						this.transitionToRoute('reports.' + this.get('selectedReport'), {
							queryParams: {
								location: this.get('singleLocationReportSelected') ? selectedLocations : selectedLocations.join(','),
								startsAt: startDateAndTime,
								endsAt: endDateAndTime
							}

						});
						break;
					default:
						this.set('invalidReportGenerated', 'Invalid report was loaded, Please try again');
				}
			}
		}
	});
});