define('menchies-dashboard/components/time-picker', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		init: function init() {
			this._super();
			var parseTime = this.get('selectedTime');
			var splitHour = parseTime.split(':');
			var hour = splitHour[0];
			var splitMin = splitHour[1].split(' ');
			var min = splitMin[0];
			var amPm = splitMin[1].toUpperCase();
			this.set('selectedTimeHour', hour);
			this.set('selectedTimeMinute', min);
			this.set('selectedAmPm', amPm);
		},

		timeHours: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
		timeMinutes: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60'],
		timeAmPm: ['AM', 'PM'],

		initTime: null,
		selectedTimeHour: null,
		selectedTimeMinute: null,
		selectedAmPm: null,
		selectedTime: null,
		watchTimeChange: _ember['default'].observer('selectedTimeHour', 'selectedTimeMinute', 'selectedAmPm', function () {
			var hour = this.get('selectedTimeHour');
			var minute = this.get('selectedTimeMinute');
			var amPm = this.get('selectedAmPm');
			var time = hour + ':' + minute + ' ' + amPm;
			this.send('sendTimes', time);
		}),
		actions: {
			sendTimes: function sendTimes(time) {
				this.set('selectedTime', time);
			}
		}
	});
});