define('menchies-dashboard/authenticators/custom', ['exports', 'ember', 'ember-simple-auth/authenticators/base'], function (exports, _ember, _emberSimpleAuthAuthenticatorsBase) {
	exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend({
		restore: function restore(sessionData) {
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				var parts = sessionData.token.split('.');
				var result = JSON.parse(window.atob(parts[1]));
				if (result.exp > Date.now()) {
					return resolve(sessionData);
				} else {
					return reject(new Error('token expired'));
				}
			});
		},
		authenticate: function authenticate(username, password) {
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				_ember['default'].$.ajax({
					type: 'POST',
					url: 'https://echo.harbortouch.com/api/v1/ext/menchies/sign-in',
					headers: {
						'Content-Type': 'application/json'
					},
					data: JSON.stringify({
						email: username,
						password: password
					})
				}).always(function (xhr, status) {
					console.log(status);
					if (xhr && xhr.token) {
						return resolve(xhr);
					} else {
						return reject(new Error('authentication failed'));
					}
				});
			});
		},
		invalidate: function invalidate(sessionData) {
			return new _ember['default'].RSVP.Promise(function (resolve, reject) {
				return resolve();
			});
		}
	});
});