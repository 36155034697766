define('menchies-dashboard/controllers/sign-in', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		session: _ember['default'].inject.service('session'),

		// Store credentials
		email: '',
		password: '',

		// Error Message
		errMessage: null,

		isLoading: false,

		actions: {
			authenticate: function authenticate() {
				var _this = this;

				_ember['default'].$('.sign-in-form').removeClass('error');
				_ember['default'].$('.email-input').removeClass('error');
				_ember['default'].$('.password-input').removeClass('error');
				if (!this.get('email') || !this.get('password')) {
					// Handle an error if someone didn't fill out these things
					if (!this.get('email') && !this.get('password')) {
						_ember['default'].$('.email-input').addClass('error');
						_ember['default'].$('.password-input').addClass('error');
						_ember['default'].$('.sign-in-form').addClass('error');
						this.set('errMessage', 'Please submit an email and password');
					} else if (!this.get('email')) {
						_ember['default'].$('.email-input').addClass('error');
						_ember['default'].$('.sign-in-form').addClass('error');
						this.set('errMessage', 'Please submit a email');
					} else if (!this.get('password')) {
						_ember['default'].$('.password-input').addClass('error');
						_ember['default'].$('.sign-in-form').addClass('error');
						this.set('errMessage', 'Please submit a password');
					};
					return;
				}

				this.set('isLoading', true);
				this.get('session').authenticate('authenticator:custom', this.get('email').trim(), this.get('password').trim())['catch'](function (e) {
					_this.set('isLoading', false);
					// Handle this error
					var message = 'The follow error occured: ' + e;
					_ember['default'].$('.sign-in-form').addClass('error');
					_this.set('errMessage', message);
				});
			}
		}
	});
});