define('menchies-dashboard/controllers/application', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Controller.extend({
		session: _ember['default'].inject.service('session'),

		// Stored properties from the token
		fullName: _ember['default'].computed(function () {
			return this.get('session.data.authenticated.name');
		}),
		email: _ember['default'].computed(function () {
			return this.get('session.data.authenticated.email');
		}),
		actions: {
			invalidateSession: function invalidateSession() {
				this.get('session').invalidate();
			}
		}
	});
});